// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { SENTRY_DSN } from "src/config";

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: SENTRY_DSN, debug: false });
}
